import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { ScrollArea } from "../components/ui/scroll-area";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "../components/ui/collapsible";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Textarea } from "../components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";
import { Label } from "../components/ui/label";
import { Checkbox } from "../components/ui/checkbox";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { Search, Plus, Grid, List, ChevronDown, ChevronUp, Info, Edit, PlusCircle, X, Send, UserPlus, Calendar, Clock, Link, Eye, Lock, Globe, Share2, CheckCircle, XCircle } from 'lucide-react';

// Mock data for forms
const forms = [
  { 
    id: 1, 
    name: 'General Intake Questionnaire', 
    category: 'Intake', 
    lastUsed: '2 days ago',
    description: 'A comprehensive intake form for new patients',
    intendedUse: 'To gather initial patient information',
    avgCompletionTime: '15 minutes',
    lastUpdated: '2023-05-15',
    relatedForms: ['Medical History', 'Insurance Information'],
    editable: true,
    content: [
      { type: 'text', label: 'Full Name', required: true },
      { type: 'date', label: 'Date of Birth', required: true },
      { type: 'radio', label: 'Gender', options: ['Male', 'Female', 'Other'], required: true },
      { type: 'textarea', label: 'Reason for Visit', required: true },
    ]
  },
  {
    id: 2,
    name: 'Informed Consent for Therapy',
    category: 'Consent',
    lastUsed: '1 week ago',
    description: 'Standard consent form for therapy services',
    intendedUse: 'To obtain patient consent for treatment',
    avgCompletionTime: '10 minutes',
    lastUpdated: '2023-04-20',
    relatedForms: ['HIPAA Privacy Notice', 'Financial Agreement'],
    editable: false,
    content: [
      { type: 'text', label: 'Patient Name', required: true },
      { type: 'checkbox', label: 'I agree to the terms of service', required: true },
      { type: 'signature', label: 'Patient Signature', required: true },
    ]
  },
  {
    id: 3,
    name: 'Depression Screening (PHQ-9)',
    category: 'Assessment',
    lastUsed: '3 days ago',
    description: 'Patient Health Questionnaire for depression screening',
    intendedUse: 'To assess severity of depression symptoms',
    avgCompletionTime: '5 minutes',
    lastUpdated: '2023-03-10',
    relatedForms: ['Anxiety Screening (GAD-7)', 'Suicide Risk Assessment'],
    editable: true,
    content: [
      { type: 'radio', label: 'Little interest or pleasure in doing things', options: ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'], required: true },
      { type: 'radio', label: 'Feeling down, depressed, or hopeless', options: ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'], required: true },
      // ... other PHQ-9 questions
    ]
  },
  {
    id: 4,
    name: 'Substance Use Screening',
    category: 'Screening',
    lastUsed: '5 days ago',
    description: 'Questionnaire to assess substance use patterns',
    intendedUse: 'To identify potential substance abuse issues',
    avgCompletionTime: '8 minutes',
    lastUpdated: '2023-02-28',
    relatedForms: ['CAGE Assessment', 'Alcohol Use Disorders Identification Test (AUDIT)'],
    editable: true,
    content: [
      { type: 'radio', label: 'How often do you have a drink containing alcohol?', options: ['Never', 'Monthly or less', '2-4 times a month', '2-3 times a week', '4 or more times a week'], required: true },
      { type: 'checkbox', label: 'Which substances have you used in the past 6 months?', options: ['Tobacco', 'Alcohol', 'Marijuana', 'Cocaine', 'Heroin', 'Prescription medications (not as prescribed)'], required: true },
    ]
  },
  {
    id: 5,
    name: 'Cognitive Behavioral Therapy (CBT) Thought Record',
    category: 'Treatment',
    lastUsed: 'Today',
    description: 'Worksheet for identifying and challenging negative thoughts',
    intendedUse: 'To help patients recognize and modify cognitive distortions',
    avgCompletionTime: '20 minutes',
    lastUpdated: '2023-05-01',
    relatedForms: ['Behavioral Activation Worksheet', 'Cognitive Restructuring Guide'],
    editable: true,
    content: [
      { type: 'text', label: 'Situation', required: true },
      { type: 'textarea', label: 'Automatic Thoughts', required: true },
      { type: 'textarea', label: 'Evidence For', required: true },
      { type: 'textarea', label: 'Evidence Against', required: true },
      { type: 'textarea', label: 'Alternative Thought', required: true },
    ]
  },
  {
    id: 6,
    name: 'Therapy Session Feedback',
    category: 'Feedback',
    lastUsed: '1 day ago',
    description: 'Form for patients to provide feedback after therapy sessions',
    intendedUse: 'To gather patient perspectives and improve therapy quality',
    avgCompletionTime: '5 minutes',
    lastUpdated: '2023-04-15',
    relatedForms: ['Treatment Satisfaction Survey', 'Therapist Evaluation Form'],
    editable: true,
    content: [
      { type: 'radio', label: "How helpful was today's session?", options: ['Not at all', 'Slightly', 'Moderately', 'Very', 'Extremely'], required: true },
      { type: 'textarea', label: 'What was the most valuable part of the session?', required: false },
      { type: 'textarea', label: 'Is there anything you wish was different about the session?', required: false },
    ]
  },
  {
    id: 7,
    name: 'Psychiatric Referral Form',
    category: 'Referral',
    lastUsed: '4 days ago',
    description: 'Form for referring patients to psychiatric services',
    intendedUse: 'To facilitate smooth referrals to psychiatrists',
    avgCompletionTime: '10 minutes',
    lastUpdated: '2023-03-20',
    relatedForms: ['Medical History Summary', 'Current Medication List'],
    editable: true,
    content: [
      { type: 'text', label: 'Patient Name', required: true },
      { type: 'date', label: 'Date of Referral', required: true },
      { type: 'textarea', label: 'Reason for Referral', required: true },
      { type: 'textarea', label: 'Current Symptoms', required: true },
      { type: 'textarea', label: 'Current Medications', required: true },
      { type: 'text', label: 'Referring Provider Name', required: true },
    ]
  },
];

const categories = ['Intake', 'Consent', 'Assessment', 'Screening', 'Treatment', 'Feedback', 'Referral'];

// Mock data for patients and stakeholders
const patients = [
  { id: 1, name: 'Alice Johnson' },
  { id: 2, name: 'Bob Smith' },
  { id: 3, name: 'Charlie Brown' },
];

const stakeholders = [
  { id: 1, name: 'John Smith', role: 'School Principal' },
  { id: 2, name: 'Sarah Lee', role: 'Social Worker' },
  { id: 3, name: 'Mike Johnson', role: 'Pediatrician' },
];

const ShareIcon = ({ isShared }) => (
  isShared ? <Globe className="h-4 w-4 text-blue-500" /> : <Lock className="h-4 w-4 text-gray-500" />
);

const ShareToggle = ({ isShared, onToggle }) => (
  <Button
    variant="ghost"
    size="sm"
    onClick={() => onToggle(!isShared)}
    className="flex items-center"
  >
    <ShareIcon isShared={isShared} />
    <span className="ml-2">{isShared ? 'Clinic Wide' : 'Just You'}</span>
  </Button>
);

const ShareFormDialog = ({ isOpen, onClose, form }) => {
  const [shareOption, setShareOption] = useState('customer');
  const [customerEmail, setCustomerEmail] = useState('');

  const handleShare = () => {
    if (shareOption === 'customer') {
      console.log(`Sharing form "${form.name}" with customer: ${customerEmail}`);
      // Implement the logic to share with a customer
    } else {
      console.log(`Sharing form "${form.name}" to global marketplace`);
      // Implement the logic to share to the global marketplace
    }
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Share Form</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <RadioGroup value={shareOption} onValueChange={setShareOption}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="customer" id="customer" />
              <Label htmlFor="customer">Share with existing customer</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="marketplace" id="marketplace" />
              <Label htmlFor="marketplace">Share to global marketplace</Label>
            </div>
          </RadioGroup>
          {shareOption === 'customer' && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="customerEmail" className="text-right">
                Customer Email
              </Label>
              <Input
                id="customerEmail"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                className="col-span-3"
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <Button onClick={handleShare}>Share</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Add "Release Of Information" to the consentForms array
const consentForms = [
  { id: '1', title: 'Patient Consent Form', status: 'completed' },
  { id: '2', title: 'Intake Questionnaire', status: 'completed' },
  { id: '3', title: 'Release of Information', status: 'not-completed' },
];

// New QuickSendModal component
const QuickSendModal = ({ isOpen, onClose, form }) => {
  const [selectedPatient, setSelectedPatient] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [newContact, setNewContact] = useState({ 
    fname: '', 
    lname: '', 
    phone: '', 
    email: '', 
    relationship: '' 
  });
  const [insertPatientData, setInsertPatientData] = useState(false);
  const [sendMethod, setSendMethod] = useState('email');
  const [manualEntry, setManualEntry] = useState(false);
  const [guardianName, setGuardianName] = useState('');

  const patients = [
    { id: '1', name: 'John Doe' },
    { id: '2', name: 'Jane Smith' },
    // Add more patients as needed
  ];

  const stakeholders = [
    { id: '1', name: 'Dr. Johnson', role: 'Primary Care Physician' },
    { id: '2', name: 'Sarah Lee', role: 'Therapist' },
    { id: '3', name: 'Bob Johnson', role: 'Dad' },
    // Add more stakeholders as needed
  ];

  const relationshipOptions = [
    'Parent', 'Teacher', 'Family', 'Partner', 'Colleague', 'Doctor (MD)', 'Doctor (PhD)', 'Other'
  ];

  const handleAddNewContact = () => {
    if (newContact.fname && newContact.lname) {
      const newStakeholder = {
        id: `new-${Date.now()}`,
        name: `${newContact.fname} ${newContact.lname}`,
        role: newContact.relationship,
        email: newContact.email,
        phone: newContact.phone
      };
      setRecipients([...recipients, newStakeholder]);
      setNewContact({ fname: '', lname: '', phone: '', email: '', relationship: '' });
    }
  };

  const handleSend = () => {
    console.log('Sending form:', {
      form,
      patient: selectedPatient,
      recipients,
      insertPatientData,
      sendMethod
    });
    onClose();
  };

  const renderRecipientROIStatus = (recipient) => (
    <span className="ml-2">
      {recipient.hasROI ? (
        <CheckCircle className="inline-block h-4 w-4 text-green-500" />
      ) : (
        <XCircle className="inline-block h-4 w-4 text-red-500" />
      )}
    </span>
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>Quick Send: {form.title}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Select value={selectedPatient} onValueChange={setSelectedPatient}>
            <SelectTrigger>
              <SelectValue placeholder="Choose Patient" />
            </SelectTrigger>
            <SelectContent>
              {patients.map((patient) => (
                <SelectItem key={patient.id} value={patient.id}>{patient.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          
          {selectedPatient && (
            <div>
              <h4 className="mb-2 font-semibold">Recipients:</h4>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id={`patient-${selectedPatient}`}
                    checked={recipients.some(r => r.id === selectedPatient)}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        const patient = patients.find(p => p.id === selectedPatient);
                        setRecipients([...recipients, { ...patient, role: 'Patient', hasROI: true }]);
                      } else {
                        setRecipients(recipients.filter(r => r.id !== selectedPatient));
                      }
                    }}
                  />
                  <Label htmlFor={`patient-${selectedPatient}`}>
                    {patients.find(p => p.id === selectedPatient)?.name} (Patient)
                    {renderRecipientROIStatus({ hasROI: true })}
                  </Label>
                </div>
                {stakeholders.map((stakeholder) => (
                  <div key={stakeholder.id} className="flex items-center space-x-2">
                    <Checkbox 
                      id={`stakeholder-${stakeholder.id}`}
                      checked={recipients.some(r => r.id === stakeholder.id)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          setRecipients([...recipients, stakeholder]);
                        } else {
                          setRecipients(recipients.filter(r => r.id !== stakeholder.id));
                        }
                      }}
                    />
                    <Label htmlFor={`stakeholder-${stakeholder.id}`}>
                      {stakeholder.name} (Stakeholder / {stakeholder.role})
                      {renderRecipientROIStatus(stakeholder)}
                    </Label>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          <div>
            <h4 className="mb-2 font-semibold">Add New Contact:</h4>
            <div className="flex items-center space-x-2 mb-2">
              <Button
                variant="outline"
                onClick={() => setManualEntry(!manualEntry)}
              >
                {manualEntry ? "Use Consent" : "Manual Entry"}
              </Button>
            </div>
            {manualEntry ? (
              <div className="grid grid-cols-2 gap-2">
                <Input 
                  placeholder="First Name" 
                  value={newContact.fname}
                  onChange={(e) => setNewContact({...newContact, fname: e.target.value})}
                />
                <Input 
                  placeholder="Last Name" 
                  value={newContact.lname}
                  onChange={(e) => setNewContact({...newContact, lname: e.target.value})}
                />
                <Input 
                  placeholder="Phone" 
                  value={newContact.phone}
                  onChange={(e) => setNewContact({...newContact, phone: e.target.value})}
                />
                <Input 
                  placeholder="Email" 
                  value={newContact.email}
                  onChange={(e) => setNewContact({...newContact, email: e.target.value})}
                />
                <Select 
                  value={newContact.relationship} 
                  onValueChange={(value) => setNewContact({...newContact, relationship: value})}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Relationship" />
                  </SelectTrigger>
                  <SelectContent>
                    {relationshipOptions.map((option) => (
                      <SelectItem key={option} value={option}>{option}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button onClick={handleAddNewContact}>Add Contact</Button>
              </div>
            ) : (
              <div>
                <Select
                  value={guardianName}
                  onValueChange={setGuardianName}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select patient/guardian" />
                  </SelectTrigger>
                  <SelectContent>
                    {patients.map((patient) => (
                      <SelectItem key={patient.id} value={patient.name}>{patient.name}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button className="mt-2" onClick={() => {
                  setNewContact({
                    fname: guardianName.split(' ')[0],
                    lname: guardianName.split(' ')[1] || '',
                    relationship: 'Guardian',
                    hasROI: true
                  });
                  handleAddNewContact();
                }}>
                  Send via Consent
                </Button>
              </div>
            )}
          </div>
          
          <div className="flex items-center space-x-2">
            <Checkbox 
              id="insertPatientData"
              checked={insertPatientData}
              onCheckedChange={setInsertPatientData}
            />
            <Label htmlFor="insertPatientData">Insert Patient Data if appropriate</Label>
          </div>
          
          <RadioGroup value={sendMethod} onValueChange={setSendMethod}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="email" id="email" />
              <Label htmlFor="email">Email</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="patientPortal" id="patientPortal" />
              <Label htmlFor="patientPortal">Patient Portal</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="generatePdf" id="generatePdf" />
              <Label htmlFor="generatePdf">Generate PDF</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="text" id="text" />
              <Label htmlFor="text">Text</Label>
            </div>
          </RadioGroup>
        </div>
        <DialogFooter>
          <Button onClick={handleSend}>Send</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

// Update FormCard and FormTableRow components to include the Quick Send button
const FormCard = ({ form, onAddToEnvelope, inEnvelope, onPreview, onInfo, onEdit, onToggleShare, onShare, isShared }) => {
  const [isQuickSendOpen, setIsQuickSendOpen] = useState(false);

  return (
    <Card className="w-full">
      <CardHeader className="pb-2">
        <div className="flex justify-between items-center">
          <CardTitle className="text-lg">
            <button onClick={() => onPreview(form)} className="hover:underline focus:outline-none">
              {form.name}
            </button>
          </CardTitle>
          <div className="flex items-center space-x-2">
            {isShared ? (
              <Button variant="outline" size="sm" onClick={() => handleAddToYourForms(form)}>
                <PlusCircle className="h-4 w-4 mr-1" /> Add to Your Forms
              </Button>
            ) : (
              <>
                <ShareToggle isShared={form.shareClinicWide} onToggle={(isShared) => onToggleShare(form.id, isShared)} />
                <Button variant="ghost" size="sm" onClick={() => onShare(form)}>
                  <Share2 className="h-4 w-4" />
                </Button>
              </>
            )}
          </div>
        </div>
        <span className="text-sm bg-gray-200 px-2 py-1 rounded">{form.category}</span>
      </CardHeader>
      <CardContent>
        <div className="w-full h-24 bg-gray-200 rounded-md mb-2 cursor-pointer" onClick={() => onPreview(form)}></div>
        <p className="text-sm text-gray-500">Last used: {form.lastUsed}</p>
        <div className="flex justify-between mt-2">
          <Button variant="ghost" size="sm" onClick={() => onInfo(form)}><Info className="w-4 h-4 mr-1" /> Info</Button>
          {form.editable && <Button variant="ghost" size="sm" onClick={() => onEdit(form)}><Edit className="w-4 h-4 mr-1" /> Edit</Button>}
          <Button variant="ghost" size="sm" onClick={() => setIsQuickSendOpen(true)}><Send className="w-4 h-4 mr-1" /> Quick Send</Button>
          <Button 
            variant={inEnvelope ? "destructive" : "default"} 
            size="sm"
            onClick={() => onAddToEnvelope(form)}
          >
            {inEnvelope ? <X className="w-4 h-4 mr-1" /> : <PlusCircle className="w-4 h-4 mr-1" />}
            {inEnvelope ? "Remove" : "Add"}
          </Button>
        </div>
      </CardContent>
      <QuickSendModal isOpen={isQuickSendOpen} onClose={() => setIsQuickSendOpen(false)} form={form} />
    </Card>
  );
};

const FormTableRow = ({ form, onAddToEnvelope, inEnvelope, onPreview, onInfo, onEdit, onToggleShare, onShare, isShared }) => {
  const [isQuickSendOpen, setIsQuickSendOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>{form.name}</TableCell>
      <TableCell>{form.category}</TableCell>
      <TableCell>{form.lastUsed}</TableCell>
      <TableCell>
        {isShared ? (
          <span>Shared by {form.sharedBy}</span>
        ) : (
          <ShareToggle isShared={form.shareClinicWide} onToggle={(isShared) => onToggleShare(form.id, isShared)} />
        )}
      </TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <Button variant="ghost" size="sm" onClick={() => onPreview(form)}><Eye className="w-4 h-4" /></Button>
          <Button variant="ghost" size="sm" onClick={() => onInfo(form)}><Info className="w-4 h-4" /></Button>
          {form.editable && <Button variant="ghost" size="sm" onClick={() => onEdit(form)}><Edit className="w-4 h-4" /></Button>}
          <Button variant="ghost" size="sm" onClick={() => setIsQuickSendOpen(true)}><Send className="w-4 h-4" /></Button>
          <Button 
            variant={inEnvelope ? "destructive" : "default"} 
            size="sm"
            onClick={() => onAddToEnvelope(form)}
          >
            {inEnvelope ? <X className="w-4 h-4" /> : <PlusCircle className="w-4 h-4" />}
          </Button>
        </div>
      </TableCell>
      <QuickSendModal isOpen={isQuickSendOpen} onClose={() => setIsQuickSendOpen(false)} form={form} />
    </TableRow>
  );
};

const SendFormsDialog = ({ envelope, onClose }) => {
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedStakeholders, setSelectedStakeholders] = useState([]);
  const [newRecipient, setNewRecipient] = useState({ name: '', role: '', contact: '' });
  const [message, setMessage] = useState('');
  const [sendOption, setSendOption] = useState('email');

  const handleAddNewRecipient = () => {
    if (newRecipient.name && newRecipient.role && newRecipient.contact) {
      setSelectedStakeholders([...selectedStakeholders, newRecipient]);
      setNewRecipient({ name: '', role: '', contact: '' });
    }
  };

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>Send Forms</DialogTitle>
        <DialogDescription>Choose recipients and review forms before sending.</DialogDescription>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="patient" className="text-right">
            Patient
          </Label>
          <Select value={selectedPatient} onValueChange={setSelectedPatient}>
            <SelectTrigger className="col-span-3">
              <SelectValue placeholder="Select patient" />
            </SelectTrigger>
            <SelectContent>
              {patients.map((patient) => (
                <SelectItem key={patient.id} value={patient.id.toString()}>{patient.name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="stakeholders" className="text-right">
            Stakeholders
          </Label>
          <div className="col-span-3">
            {stakeholders.map((stakeholder) => (
              <div key={stakeholder.id} className="flex items-center space-x-2">
                <Checkbox 
                  id={`stakeholder-${stakeholder.id}`}
                  checked={selectedStakeholders.some(s => s.id === stakeholder.id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedStakeholders([...selectedStakeholders, stakeholder]);
                    } else {
                      setSelectedStakeholders(selectedStakeholders.filter(s => s.id !== stakeholder.id));
                    }
                  }}
                />
                <Label htmlFor={`stakeholder-${stakeholder.id}`}>{stakeholder.name} ({stakeholder.role})</Label>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right">New Recipient</Label>
          <div className="col-span-3 space-y-2">
            <Input 
              placeholder="Name" 
              value={newRecipient.name}
              onChange={(e) => setNewRecipient({...newRecipient, name: e.target.value})}
            />
            <Input 
              placeholder="Role" 
              value={newRecipient.role}
              onChange={(e) => setNewRecipient({...newRecipient, role: e.target.value})}
            />
            <Input 
              placeholder="Contact Information" 
              value={newRecipient.contact}
              onChange={(e) => setNewRecipient({...newRecipient, contact: e.target.value})}
            />
            <Button onClick={handleAddNewRecipient}>
              <UserPlus className="w-4 h-4 mr-2" />
              Add Recipient
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 items-start gap-4">
          <Label className="text-right">Forms to Send</Label>
          <div className="col-span-3">
            {envelope.map((form) => (
              <div key={form.id} className="flex items-center justify-between py-1">
                <span>{form.name}</span>
                <Button variant="ghost" size="sm">
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="message" className="text-right">
            Message
          </Label>
          <Textarea 
            id="message" 
            placeholder="Add an optional cover message" 
            className="col-span-3"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label className="text-right">Send Options</Label>
          <RadioGroup 
            className="col-span-3"
            value={sendOption}
            onValueChange={setSendOption}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="email" id="email" />
              <Label htmlFor="email">Email</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="portal" id="portal" />
              <Label htmlFor="portal">Patient Portal</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="pdf" id="pdf" />
              <Label htmlFor="pdf">Generate Print-ready PDF</Label>
            </div>
          </RadioGroup>
        </div>
      </div>
      <DialogFooter>
        <Button type="submit">Send Forms</Button>
      </DialogFooter>
    </DialogContent>
  );
};

const FormPreviewModal = ({ form, onClose, onAddToEnvelope, onEdit }) => (
  <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
    <DialogHeader>
      <DialogTitle>{form.name}</DialogTitle>
      <DialogDescription>Preview of the form</DialogDescription>
    </DialogHeader>
    <div className="py-4">
      {form.content.map((field, index) => (
        <div key={index} className="mb-4">
          <Label htmlFor={`field-${index}`}>{field.label}</Label>
          {field.type === 'text' && <Input id={`field-${index}`} placeholder={field.label} disabled />}
          {field.type === 'date' && <Input id={`field-${index}`} type="date" disabled />}
          {field.type === 'radio' && (
            <RadioGroup>
              {field.options.map((option, optionIndex) => (
                <div key={optionIndex} className="flex items-center space-x-2">
                  <RadioGroupItem value={option} id={`${field.label}-${option}`} disabled />
                  <Label htmlFor={`${field.label}-${option}`}>{option}</Label>
                </div>
              ))}
            </RadioGroup>
          )}
          {field.type === 'textarea' && <Textarea id={`field-${index}`} placeholder={field.label} disabled />}
        </div>
      ))}
    </div>
    <DialogFooter>
      <Button onClick={() => onAddToEnvelope(form)}>Use This Form</Button>
      {form.editable && <Button onClick={() => onEdit(form)}>Edit</Button>}
      <Button variant="outline" onClick={onClose}>Close Preview</Button>
    </DialogFooter>
  </DialogContent>
);

const FormInformationSidebar = ({ form, onClose }) => (
  <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-lg p-4 overflow-y-auto z-50">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold">{form.name}</h2>
      <Button variant="ghost" size="sm" onClick={onClose}>
        <X className="h-4 w-4" />
      </Button>
    </div>
    <div className="space-y-4">
      <div>
        <h3 className="font-semibold">Description</h3>
        <p>{form.description}</p>
      </div>
      <div>
        <h3 className="font-semibold">Intended Use</h3>
        <p>{form.intendedUse}</p>
      </div>
      <div className="flex items-center">
        <Clock className="w-4 h-4 mr-2" />
        <span>Average completion time: {form.avgCompletionTime}</span>
      </div>
      <div className="flex items-center">
        <Calendar className="w-4 h-4 mr-2" />
        <span>Last updated: {form.lastUpdated}</span>
      </div>
      <div>
        <h3 className="font-semibold">Related Forms</h3>
        <ul className="list-disc list-inside">
          {form.relatedForms.map((relatedForm, index) => (
            <li key={index}>{relatedForm}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

// Add this to your mock data
const sharedForms = [
  {
    id: 101,
    name: 'Collaborative Assessment Form',
    category: 'Assessment',
    lastUsed: '1 week ago',
    sharedBy: 'Dr. Jane Doe',
    description: 'A comprehensive assessment form for collaborative patient evaluation',
    // ... other form properties
  },
  // Add more shared forms as needed
];

const FormsLibrary = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('grid');
  const [searchTerm, setSearchTerm] = useState('');
  const [envelope, setEnvelope] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState(categories.reduce((acc, category) => ({ ...acc, [category]: true }), {}));
  const [sendFormsOpen, setSendFormsOpen] = useState(false);
  const [previewForm, setPreviewForm] = useState(null);
  const [infoForm, setInfoForm] = useState(null);
  const [editForm, setEditForm] = useState(null);
  const [shareForm, setShareForm] = useState(null);

  const filteredForms = forms.filter(form => 
    form.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    form.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({ ...prev, [category]: !prev[category] }));
  };

  const addToEnvelope = (form) => {
    if (envelope.find(f => f.id === form.id)) {
      setEnvelope(envelope.filter(f => f.id !== form.id));
    } else {
      setEnvelope([...envelope, form]);
    }
  };

  const handleEditForm = (form) => {
    setEditForm(form);
    // Here you would typically navigate to the Form Editor component
    console.log('Navigating to Form Editor with form:', form);
  };

  const handleCreateNewForm = () => {
    navigate('/form-editor');
  };

  const handleToggleShare = (formId, isShared) => {
    const updatedForms = forms.map(form => 
      form.id === formId ? { ...form, shareClinicWide: isShared } : form
    );
    // Here you would typically update the forms in your state or send an API request
    console.log(`Form ${formId} sharing status changed to: ${isShared ? 'Clinic Wide' : 'Just You'}`);
  };

  const handleShare = (form) => {
    setShareForm(form);
  };

  const renderForms = (formsList) => {
    if (view === 'grid') {
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {formsList.map(form => (
            <FormCard 
              key={form.id} 
              form={form} 
              onAddToEnvelope={addToEnvelope}
              inEnvelope={envelope.some(f => f.id === form.id)}
              onPreview={() => setPreviewForm(form)}
              onInfo={() => setInfoForm(form)}
              onEdit={() => handleEditForm(form)}
              onToggleShare={handleToggleShare}
              onShare={handleShare}
              isShared={formsList === sharedForms}
            />
          ))}
        </div>
      );
    } else {
      return (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Last Used</TableHead>
              <TableHead>Sharing</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {formsList.map(form => (
              <FormTableRow 
                key={form.id} 
                form={form} 
                onAddToEnvelope={addToEnvelope}
                inEnvelope={envelope.some(f => f.id === form.id)}
                onPreview={() => setPreviewForm(form)}
                onInfo={() => setInfoForm(form)}
                onEdit={() => handleEditForm(form)}
                onToggleShare={handleToggleShare}
                onShare={handleShare}
                isShared={formsList === sharedForms}
              />
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  const handleAddToYourForms = (form) => {
    console.log(`Adding shared form "${form.name}" to your forms`);
    // Implement the logic to add the shared form to the user's forms
  };

  return (
    <div className="container mx-auto p-4 flex">
      <div className="flex-grow mr-4">
        <div className="flex justify-between items-center mb-4">
          <div className="relative w-1/2">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              type="text"
              placeholder="Search forms..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={handleCreateNewForm}><Plus className="w-4 h-4 mr-1" /> Create New Form</Button>
            <Button variant="outline" onClick={() => setView('grid')}><Grid className="w-4 h-4" /></Button>
            <Button variant="outline" onClick={() => setView('list')}><List className="w-4 h-4" /></Button>
          </div>
        </div>

        <Card className="mb-4">
          <CardHeader>
            <CardTitle>Your Forms</CardTitle>
          </CardHeader>
          <CardContent>
            {renderForms(filteredForms.slice(0, 3))}
            <Button variant="link" className="mt-4">Manage Your Forms</Button>
          </CardContent>
        </Card>

        <Tabs defaultValue="all" className="w-full">
          <TabsList className="w-full bg-gray-100 p-1 rounded-lg">
            <TabsTrigger 
              value="all" 
              className="flex-1 py-2 px-4 rounded-md data-[state=active]:bg-white data-[state=active]:shadow-sm transition-all"
            >
              All Forms
            </TabsTrigger>
            <TabsTrigger 
              value="recent" 
              className="flex-1 py-2 px-4 rounded-md data-[state=active]:bg-white data-[state=active]:shadow-sm transition-all"
            >
              Recently Used
            </TabsTrigger>
            <TabsTrigger 
              value="shared" 
              className="flex-1 py-2 px-4 rounded-md data-[state=active]:bg-white data-[state=active]:shadow-sm transition-all"
            >
              Shared With You
            </TabsTrigger>
          </TabsList>
          <TabsContent value="all">
            {categories.map(category => (
              <Collapsible key={category} open={expandedCategories[category]} onOpenChange={() => toggleCategory(category)}>
                <CollapsibleTrigger asChild>
                  <Button variant="ghost" className="w-full justify-between mb-2">
                    {category}
                    {expandedCategories[category] ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                  </Button>
                </CollapsibleTrigger>
                <CollapsibleContent>
                  {renderForms(filteredForms.filter(form => form.category === category))}
                </CollapsibleContent>
              </Collapsible>
            ))}
          </TabsContent>
          <TabsContent value="recent">
            {renderForms(filteredForms.slice(0, 5))}
          </TabsContent>
          <TabsContent value="shared">
            {renderForms(sharedForms)}
          </TabsContent>
        </Tabs>
      </div>

      <Card className="w-64 h-fit sticky top-4">
        <CardHeader>
          <CardTitle>Envelope</CardTitle>
        </CardHeader>
        <CardContent>
          <ScrollArea className="h-[300px]">
            {envelope.map(form => (
              <div key={form.id} className="flex justify-between items-center mb-2">
                <span className="text-sm">{form.name}</span>
                <Button variant="ghost" size="sm" onClick={() => addToEnvelope(form)}>
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </ScrollArea>
          <div className="flex flex-col space-y-2 mt-4">
            <Dialog open={sendFormsOpen} onOpenChange={setSendFormsOpen}>
              <DialogTrigger asChild>
                <Button className="w-full" disabled={envelope.length === 0}>
                  <Send className="w-4 h-4 mr-1" /> Send Forms ({envelope.length})
                </Button>
              </DialogTrigger>
              <SendFormsDialog envelope={envelope} onClose={() => setSendFormsOpen(false)} />
            </Dialog>
            <Button variant="outline" className="w-full" onClick={() => setEnvelope([])}>
              Clear Envelope
            </Button>
          </div>
        </CardContent>
      </Card>

      <Dialog open={previewForm !== null} onOpenChange={() => setPreviewForm(null)}>
        {previewForm && (
          <FormPreviewModal 
            form={previewForm} 
            onClose={() => setPreviewForm(null)}
            onAddToEnvelope={addToEnvelope}
            onEdit={() => {
              handleEditForm(previewForm);
              setPreviewForm(null);
            }}
          />
        )}
      </Dialog>

      {infoForm && (
        <FormInformationSidebar 
          form={infoForm} 
          onClose={() => setInfoForm(null)}
        />
      )}

      <ShareFormDialog
        isOpen={shareForm !== null}
        onClose={() => setShareForm(null)}
        form={shareForm}
      />
    </div>
  );
};

export default FormsLibrary;