import React, { useState } from 'react';
import { Search, ChevronRight, Plus, Edit2, ExternalLink, BookOpen, Users, Smartphone, Brain, Link } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";

const diagnoses = [
  { id: 'adhd', name: 'ADHD', category: 'Neurodevelopmental' },
  { id: 'anxiety', name: 'Anxiety Disorders', category: 'Anxiety' },
  { id: 'depression', name: 'Depression', category: 'Mood' },
  { id: 'asd', name: 'Autism Spectrum Disorder', category: 'Neurodevelopmental' },
  { id: 'learning', name: 'Learning Disorders', category: 'Educational' },
  { id: 'ocd', name: 'OCD', category: 'Anxiety' },
  { id: 'ptsd', name: 'PTSD', category: 'Trauma' },
  { id: 'bipolar', name: 'Bipolar Disorder', category: 'Mood' }
];

const resourceCategories = [
  {
    icon: <BookOpen className="text-blue-500" />,
    title: 'Reading Materials',
    items: [
      { name: 'Understanding ADHD: A Guide for Parents', url: '#' },
      { name: 'Executive Function Skills Workbook', url: '#' },
      { name: 'School Accommodation Guidelines', url: '#' }
    ]
  },
  {
    icon: <Users className="text-green-500" />,
    title: 'Support Groups',
    items: [
      { name: 'Local CHADD Chapter Meetings', url: '#' },
      { name: 'Parent Support Network', url: '#' },
      { name: 'Online Communities', url: '#' }
    ]
  },
  {
    icon: <Smartphone className="text-purple-500" />,
    title: 'Apps & Technology',
    items: [
      { name: 'Focus@Will - Concentration App', url: '#' },
      { name: 'Todoist - Task Management', url: '#' },
      { name: 'Calm - Meditation & Focus', url: '#' }
    ]
  },
  {
    icon: <Brain className="text-orange-500" />,
    title: 'Therapeutic Resources',
    items: [
      { name: 'CBT Worksheets', url: '#' },
      { name: 'Mindfulness Exercises', url: '#' },
      { name: 'Behavior Charts', url: '#' }
    ]
  }
];

export default function Resources() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
  const [customRecommendations, setCustomRecommendations] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const filteredDiagnoses = diagnoses.filter(diagnosis => 
    diagnosis.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    diagnosis.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white border-r overflow-y-auto">
        <div className="p-4">
          <h2 className="text-lg font-bold mb-4">Diagnoses</h2>
          <div className="mb-4">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Search diagnoses..."
                className="pl-9"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="space-y-1">
            {filteredDiagnoses.map((diagnosis) => (
              <button
                key={diagnosis.id}
                onClick={() => setSelectedDiagnosis(diagnosis)}
                className={`w-full text-left p-2 rounded-lg flex items-center justify-between ${
                  selectedDiagnosis?.id === diagnosis.id 
                    ? 'bg-blue-50 text-blue-600' 
                    : 'hover:bg-gray-50'
                }`}
              >
                <span>{diagnosis.name}</span>
                <ChevronRight className="h-4 w-4" />
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-y-auto">
        {selectedDiagnosis ? (
          <div className="p-6 space-y-6">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">{selectedDiagnosis.name} Resources</h1>
              <Button variant="outline">
                <Plus className="h-4 w-4 mr-2" />
                Add Resource
              </Button>
            </div>

            {/* Standard Resources */}
            <div className="grid grid-cols-2 gap-4">
              {resourceCategories.map((category, index) => (
                <Card key={index}>
                  <CardHeader>
                    <CardTitle className="flex items-center">
                      {category.icon}
                      <span className="ml-2">{category.title}</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="space-y-2">
                      {category.items.map((item, itemIndex) => (
                        <li key={itemIndex} className="flex items-center justify-between">
                          <span>{item.name}</span>
                          <Button variant="ghost" size="sm">
                            <ExternalLink className="h-4 w-4" />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>

            {/* Custom Recommendations */}
            <Card>
              <CardHeader>
                <div className="flex items-center justify-between">
                  <CardTitle>Additional Recommendations By Dr. Smith</CardTitle>
                  <Button 
                    variant="ghost"
                    size="sm"
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    <Edit2 className="h-4 w-4" />
                  </Button>
                </div>
              </CardHeader>
              <CardContent>
                {isEditing ? (
                  <div className="space-y-4">
                    <Textarea
                      value={customRecommendations}
                      onChange={(e) => setCustomRecommendations(e.target.value)}
                      rows={6}
                      placeholder="Enter your custom recommendations..."
                    />
                    <div className="flex justify-end space-x-2">
                      <Button variant="outline" onClick={() => setIsEditing(false)}>
                        Cancel
                      </Button>
                      <Button onClick={() => setIsEditing(false)}>
                        Save
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="prose">
                    {customRecommendations || 
                      <span className="text-gray-400 italic">
                        No custom recommendations added yet.
                      </span>
                    }
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            Select a diagnosis to view resources
          </div>
        )}
      </div>
    </div>
  );
} 