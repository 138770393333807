import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Home, FileText, Users, Settings, Search, User, ChevronUp, ChevronDown, X, Copy, MessageSquare, HelpCircle, Clock, FileSpreadsheet, Brain, Mail, Stethoscope, Edit, BookOpen } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Checkbox } from "../components/ui/checkbox";
import { Label } from "../components/ui/label";

const SearchModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-2xl p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Search</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search..."
          className="w-full p-2 border border-gray-300 rounded mb-4"
        />
        <div>
          <h3 className="text-sm font-semibold text-gray-500 mb-2">Recent Searches</h3>
          <ul className="space-y-2">
            <li>
              <Link to="/patient-record/john-doe" className="text-blue-500 hover:underline">John Doe (Patient Record)</Link>
            </li>
            <li>
              <Link to="/document-manager/intake-forms" className="text-blue-500 hover:underline">Intake Forms (Document Manager)</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const AskAIModal = ({ isOpen, onClose }) => {
  const [questionType, setQuestionType] = useState(null);
  const [patientName, setPatientName] = useState('');
  const [addToPatientFile, setAddToPatientFile] = useState(false);
  const [chatMode, setChatMode] = useState(false);

  const handleQuestionTypeSelect = (type) => {
    setQuestionType(type);
    if (type === 'general') {
      setChatMode(true);
    }
  };

  const handlePatientNameSubmit = (e) => {
    e.preventDefault();
    setChatMode(true);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Ask AI</DialogTitle>
        </DialogHeader>
        {!questionType ? (
          <div className="flex flex-col space-y-4">
            <Button onClick={() => handleQuestionTypeSelect('patient')}>
              Patient Specific Question
            </Button>
            <Button onClick={() => handleQuestionTypeSelect('general')}>
              General Question
            </Button>
          </div>
        ) : questionType === 'patient' && !chatMode ? (
          <form onSubmit={handlePatientNameSubmit} className="space-y-4">
            <Input
              placeholder="Enter patient name"
              value={patientName}
              onChange={(e) => setPatientName(e.target.value)}
            />
            <Button type="submit">Submit</Button>
          </form>
        ) : (
          <div className="space-y-4">
            <div className="border p-4 h-60 overflow-y-auto">
              {questionType === 'patient' ? (
                <p>How can I help you with {patientName.split(' ')[0]}?</p>
              ) : (
                <p>How can I assist you today?</p>
              )}
              {/* Chat messages would be rendered here */}
            </div>
            <Input placeholder="Type your message..." />
            <div className="flex items-center space-x-2">
              <Checkbox
                id="addToPatientFile"
                checked={addToPatientFile}
                onCheckedChange={setAddToPatientFile}
              />
              <Label htmlFor="addToPatientFile">Add conversation to patient file</Label>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const SideNav = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isAskAIModalOpen, setIsAskAIModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const toggleSupport = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  const openAskAIModal = () => {
    setIsAskAIModalOpen(true);
  };

  const closeAskAIModal = () => {
    setIsAskAIModalOpen(false);
  };

  return (
    <div className="flex h-screen">
      <nav className="bg-gray-800 text-white w-64 min-h-screen p-4 flex flex-col">
        <div className="mb-8">
          <h1 className="text-2xl font-bold">PsychAccess AI</h1>
        </div>
        <div className="mb-4">
          <button onClick={openSearchModal} className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded w-full">
            <Search className="h-5 w-5" />
            <span>Search</span>
          </button>
        </div>
        <ul className="space-y-2 flex-grow">
          <li>
            <Link to="/" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Home className="h-5 w-5" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/document-manager" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <FileText className="h-5 w-5" />
              <span>Document Manager</span>
            </Link>
          </li>
          <li>
            <Link to="/patient-records-crm" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Users className="h-5 w-5" />
              <span>Patient Records</span>
            </Link>
          </li>
          <li>
            <Link to="/settings" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Settings className="h-5 w-5" />
              <span>Settings</span>
            </Link>
          </li>
          <li>
            <Link to="/forms-library" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <FileSpreadsheet className="h-5 w-5" />
              <span>Forms Library</span>
            </Link>
          </li>
          <li>
            <Link to="/email-templates" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Mail className="h-5 w-5" />
              <span>Email Templates</span>
            </Link>
          </li>
          <li>
            <Link to="/session" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Stethoscope className="h-5 w-5" />
              <span>Session Interface</span>
            </Link>
          </li>
          <li>
            <Link to="/report-writer" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <Edit className="h-5 w-5" />
              <span>Report Writer</span>
            </Link>
          </li>
          <li>
            <Link to="/resources" className="flex items-center space-x-2 p-2 hover:bg-gray-700 rounded">
              <BookOpen className="h-5 w-5" />
              <span>Resources</span>
            </Link>
          </li>
        </ul>
        
        {/* New Ask AI Box */}
        <div className="mb-4 p-4 bg-gray-700 rounded-lg border border-white">
          <button
            onClick={openAskAIModal}
            className="flex items-center justify-between w-full text-left"
          >
            <div className="flex items-center">
              <Brain className="h-5 w-5 mr-2" />
              <h3 className="text-lg font-semibold">Ask AI</h3>
            </div>
          </button>
        </div>

        {/* Updated Support Section */}
        <div className="mb-4 p-4 bg-gray-700 rounded-lg border border-white">
          <button
            onClick={toggleSupport}
            className="flex items-center justify-between w-full text-left"
          >
            <h3 className="text-lg font-semibold">SUPPORT:</h3>
            {isSupportOpen ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
          </button>
          <div className="flex items-center text-sm mt-2">
            <span>PIN: 3487</span>
            <button className="ml-2" onClick={() => navigator.clipboard.writeText('3487')}>
              <Copy className="h-4 w-4" />
            </button>
          </div>
          {isSupportOpen && (
            <div className="mt-2 space-y-2">
              <button className="w-full text-left p-2 hover:bg-gray-600 rounded flex items-center">
                <MessageSquare className="h-4 w-4 mr-2" />
                LIVE CHAT
              </button>
              <button className="w-full text-left p-2 hover:bg-gray-600 rounded flex items-center">
                <HelpCircle className="h-4 w-4 mr-2" />
                SUPPORT SITE
              </button>
              <button className="w-full text-left p-2 hover:bg-gray-600 rounded flex items-center">
                <Clock className="h-4 w-4 mr-2" />
                TICKET STATUS
              </button>
            </div>
          )}
        </div>

        <div className="mt-auto">
          <button
            onClick={toggleUserMenu}
            className="flex items-center justify-between w-full p-2 hover:bg-gray-700 rounded"
          >
            <div className="flex items-center space-x-2">
              <User className="h-5 w-5" />
              <span>Dr. Smith (ABC Clinic)</span>
            </div>
            {isUserMenuOpen ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
          </button>
          {isUserMenuOpen && (
            <ul className="mt-2 space-y-1">
              <li>
                <button className="w-full text-left p-2 hover:bg-gray-700 rounded">Switch Company</button>
              </li>
              <li>
                <button className="w-full text-left p-2 hover:bg-gray-700 rounded">Logout</button>
              </li>
              <li>
                <button className="w-full text-left p-2 hover:bg-gray-700 rounded">Profile</button>
              </li>
            </ul>
          )}
        </div>
      </nav>
      <main className="flex-1 overflow-auto bg-gray-100">
        <Outlet />
      </main>
      <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
      <AskAIModal isOpen={isAskAIModalOpen} onClose={closeAskAIModal} />
    </div>
  );
};

export default SideNav;
