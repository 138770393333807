import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { FileText, Book, ClipboardList } from "lucide-react";

export function LibrariesTile() {
  return (
    <Card>
      <CardHeader className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
        <CardTitle className="text-lg font-bold">Libraries</CardTitle>
      </CardHeader>
      <CardContent className="p-4">
        <div className="space-y-2">
          <Button variant="outline" className="w-full justify-start" size="sm">
            <FileText className="mr-2 h-4 w-4" />
            Documents
          </Button>
          <Button variant="outline" className="w-full justify-start" size="sm">
            <Book className="mr-2 h-4 w-4" />
            Templates
          </Button>
          <Button variant="outline" className="w-full justify-start" size="sm">
            <ClipboardList className="mr-2 h-4 w-4" />
            Assessments
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}